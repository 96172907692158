import axios from 'axios'
import router from '../router'
import { captureSentryError } from '../util/captureSentryError'

// Always query the same host where app is served.
// In dev mode, env variable VUE_APP_API_DOMAIN used, see vue.config.js in root directory, devServer.proxy section.
const endpoint = '/api'

export const apiClient = axios.create({
  baseURL: endpoint,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('guestToken') || localStorage.getItem('authToken')
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

apiClient.interceptors.response.use(
  (response) => {
    const originalRequest = response.config
    if (response.data && response.data.status && response.data.status > 299) {
      const { config, data } = response
      captureSentryError(new Error(response.data.message), config.data, data.status, data.message ? data.message : 'Unknown', config.url, config.headers, config.method)
    }
    if (response.status === 201) {
      originalRequest._retry = true
      const refreshToken = localStorage.getItem('refreshToken')

      if (!refreshToken) {
        redirectToMain()
        return Promise.reject("Refresh token is missing");
      }

      return apiClient.post('refresh-token', {
          refresh_token: refreshToken,
        }).then((res) => {
          if (res.status === 200 && res.data.status === 200 && res.data.data.token) {
            localStorage.removeItem('guestToken')
            localStorage.setItem('authToken', res.data.data.token)
            apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('authToken')
            return apiClient(originalRequest)
          } else {
            redirectToMain()
            return Promise.reject('Failed to refresh token')
          }
        }).catch((err) => {
          redirectToMain()
          return Promise.reject(err)
        })
    }
    return response
  },
  (error) => {
    const { response, config } = error

    captureSentryError(error, config?.data, response ? response.status : 'Unknown', response ? response.data : 'Unknown', config?.url, config?.headers, config?.method)

    return Promise.reject(error)
  }
)

const redirectToMain = (path = '/auth/login') => {
  localStorage.clear()
  if (router.currentRoute.path !== path) {
    router.replace(path)
  }
}

export const setLangHeader = (lang) => {
  apiClient.defaults.headers.common['UserLanguage'] = lang ? lang : 'en'
}

setLangHeader(localStorage.getItem('lang'))
export default apiClient
